import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage2 from '../../components/images/BackgroundImage2';
import QMCloudsLaatudokumentitImage from '../../components/images/QMCloudsLaatudokumentitImage';

export default () => {
  const { t } = useTranslation();

  return (
    <BackgroundImage2>
      <Container className="container-space text-white">
        <Row>
          <Col>
            <h2 className="text-white">{t('ApprovalSystemTitle')}</h2>
            <div className="line white-line"></div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="text-white">{t('ApprovalSystemSubTitle1')}</h3>
            <p>{t('ApprovalSystemPara1')}</p>
            <h3 className="text-white">{t('ApprovalSystemSubTitle2')}</h3>
            <p>{t('ApprovalSystemPara2_1')}</p>
            <p>{t('ApprovalSystemPara2_2')}</p>
            <p>{t('ApprovalSystemPara2_3')}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <QMCloudsLaatudokumentitImage className="img-rounded" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage2>
  );
};
