import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import QMCloudsLaptopMobileImage from '../../components/images/QMCloudsLaptopMobileImg';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space">
      <Row>
        <Col>
          <h2 className="text-blue">{t('QmcloudsSoftware')}</h2>
          <div className="line blue-line"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="text-blue">
            {t('ImproveQualityManagementSubTitle1')}
          </h3>
          <p>{t('ImproveQualityManagementPara1')}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mt-3">
          <h3 className="text-blue">{t('FeaturesSubTitle2')}</h3>
          <p>{t('FeaturesPara2')}</p>
          <ContactRequest />
        </Col>
        <Col lg={6} className="mt-4">
          <QMCloudsLaptopMobileImage className="img-w-500" />
        </Col>
      </Row>
    </Container>
  );
};
