import React from 'react';
import { Col } from 'react-bootstrap';

interface Props {
  title: string;
  paragraph: string;
  image: string;
  blueCircleAlt?: string;
}

export default (props: Props) => {
  const { title, paragraph, image } = props;
  return (
    <>
      <Col lg={2}>
        <img
          src={image}
          className="img img-w-50 block m-auto"
          alt="Sininen ympyrä"
        />
      </Col>
      <Col lg={10}>
        <h4 className="text-blue">{title}</h4>
        <p>{paragraph}</p>
      </Col>
    </>
  );
};
