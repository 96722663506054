import React from 'react';
import DocumentTemplates from '../components/DocumentTemplates';
import Layout from '../components/layout';
import QualityManualRequestWithBg from '../components/QualityManualRequestWithBg';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import ApprovalSystem from '../pages-partials/software/ApprovalSystem';
import Features from '../pages-partials/software/Features';
import HowToStart from '../pages-partials/software/HowToStart';
import ImproveQualityManagement from '../pages-partials/software/ImproveQualityManagement';
import SoftwareHero from '../pages-partials/software/SoftwareHero';
import WhyQualityManagementTool from '../pages-partials/software/WhyQualityManagementTool';

const SoftwarePage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('softwareSeoTitle')}
      description={t('softwareSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/qmclouds-software"
      lang={locale}
    />
    <SoftwareHero />
    <ImproveQualityManagement />
    <Features />
    <WhyQualityManagementTool />
    <ApprovalSystem />
    <HowToStart />
    <QualityManualRequestWithBg />
    <DocumentTemplates />
  </Layout>
);
export default withI18next({ ns: 'common' })(SoftwarePage);
