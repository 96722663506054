import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsTietokonePuhelinImg from '../../components/images/QMCloudsTietokonePuhelinImg';
import LinkTo from '../../components/LinkTo';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage className="hero">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 className="mb-4">{t('ImproveQualityManagementTitle')}</h1>
            <LinkTo
              url={`${urls.softwareUrl}#features`}
              text={t('LinkToFeatures')}
              hoverWhite
            />
            <LinkTo
              url={`${urls.qualityUrl}#documents`}
              text={t('IndexLinkToDocuments')}
              hoverWhite
            />
          </Col>
          <Col lg={6} className="mt-5">
            <QMCloudsTietokonePuhelinImg />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
