import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactDemoRequest from '../../components/ContactDemoRequest';
import HowToStartTextPiece from '../../components/HowToStartTextPiece';
import TextPiece from '../../components/TextPiece';
import blueCircle from '../../images/blueball.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space">
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h2"
            subHTag="h3"
            title={t('HowToStartTitle')}
            subTitle={t('HowToStartSubTitle')}
            paragraphs={[t('HowToStartPara1')]}
          />
          <ContactDemoRequest />
        </Col>
        <Col lg={6}>
          <Row>
            <HowToStartTextPiece
              image={blueCircle}
              title={t('HowToStartTextPieceTitle1')}
              paragraph={t('HowToStartTextPiecePara1')}
            />
            <HowToStartTextPiece
              image={blueCircle}
              title={t('HowToStartTextPieceTitle2')}
              paragraph={t('HowToStartTextPiecePara2')}
            />
            <HowToStartTextPiece
              image={blueCircle}
              title={t('HowToStartTextPieceTitle3')}
              paragraph={t('HowToStartTextPiecePara3')}
            />
            <HowToStartTextPiece
              image={blueCircle}
              title={t('HowToStartTextPieceTitle4')}
              paragraph={t('HowToStartTextPiecePara4')}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
