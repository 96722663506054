import Axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const initValues = {
  email: '',
};

type ContactDemoRequestFormBody = typeof initValues;

interface Props {
  isBackgroundBlue?: boolean;
}


export default (props: Props): JSX.Element => {
  const [isContactDemoRequestSend, setIsContactDemoRequestSend] =
    useState(false);

  const { isBackgroundBlue = false } = props;
  const [isInvalid, setIsInvalid] = useState(false);
  const { t } = useTranslation();

  let textClassName = '';

  const contactDemoRequest = useCallback(
    (values: ContactDemoRequestFormBody) => {
      if (values.email === '') {
        setIsInvalid(true);
      } else {
        let url = 'https://formspree.io/f/xwkalpee';
        if (process.env.NODE_ENV !== 'production') {
          url = 'https://formspree.io/xpzrrlkb';
        }

        const body = {
          message: `Demo-tunnusten tilaaminen (QMClouds): ${values.email}`,
          _subject: `Yhteydenottopyyntö Demo: ${values.email}`,
        };

        window.gtag("event", 'submit', {
          event_category: 'Contact Demo request'
        } )

        Axios.post(url, body)
          .then(() => {
            setIsContactDemoRequestSend(true);
          })
          .catch(() => {
            setIsInvalid(true);
          });
      }
    },
    [setIsInvalid]
  );

  if (isBackgroundBlue === true) {
    textClassName = 'text-white';
  }
  return (
    <Formik initialValues={initValues} onSubmit={contactDemoRequest}>
      {isContactDemoRequestSend ? (
        <Row>
          <Col>
            <h5 className="text-success font-weight-bold">
              {t('ContactDemoRequestSend')}
            </h5>
          </Col>
        </Row>
      ) : (
        <Form className="contact-request-form">
          <Row>
            <Col>
              <div>
                <p
                  className={`font-weight-bold text-blue mb-1 ${textClassName}`}
                >
                  {t('ContactDemoRequestLeaveYourInformation')}
                </p>
              </div>
              <div className="form-group">
                <Field
                  type="text"
                  className="form-control"
                  placeholder={t(
                    'ContactDemoRequestGiveYourInformationPlaceholder'
                  )}
                  name="email"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <button type="submit" className="btn btn-primary btn-block">
                {t('ContactDemoRequestButton')}
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              {isInvalid ? <p>{t('ContactDemoRequestErrorMessage')}</p> : ''}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
