import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextPiece from '../../components/TextPiece';
import disciplineImg from '../../images/discipline.svg';
import icon10 from '../../images/icon10.svg';
import icon12 from '../../images/icon12.svg';
import icon9 from '../../images/icon9.svg';
import ideaImg from '../../images/idea.svg';
import performanceImg from '../../images/performance.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space spacing-50">
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h4"
            subHTag="h5"
            icon={icon12}
            title={t('WhyQualityManagementToolTitle1')}
            subTitle={t('WhyQualityManagementToolSubTitle1')}
            paragraphs={[t('WhyQualityManagementToolPara1')]}
          />
        </Col>
        <Col lg={6}>
          <img
            src={disciplineImg}
            className="img img-w-350 d-none d-lg-block m-auto"
            alt={t('WhyQualityManagementToolImageAlt1')}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <img
            src={ideaImg}
            className="img img-w-350 d-none d-lg-block m-auto"
            alt={t('WhyQualityManagementToolImageAlt2')}
          />
        </Col>
        <Col lg={6}>
          <TextPiece
            hTag="h4"
            subHTag="h5"
            icon={icon9}
            title={t('WhyQualityManagementToolTitle2')}
            subTitle={t('WhyQualityManagementToolSubTitle2')}
            paragraphs={[t('WhyQualityManagementToolPara2')]}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h4"
            subHTag="h5"
            icon={icon10}
            title={t('WhyQualityManagementToolTitle3')}
            subTitle={t('WhyQualityManagementToolSubTitle3')}
            paragraphs={[t('WhyQualityManagementToolPara3')]}
          />
        </Col>
        <Col lg={6}>
          <img
            src={performanceImg}
            className="img img-w-350 block m-auto"
            alt={t('WhyQualityManagementToolImageAlt3')}
          />
        </Col>
      </Row>
    </Container>
  );
};
