import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Feature from '../../components/Feature';
import icon1 from '../../images/icon11.svg';
import icon2 from '../../images/icon22.svg';
import icon3 from '../../images/icon33.svg';
import icon4 from '../../images/icon44.svg';
import icon5 from '../../images/icon55.svg';
import icon6 from '../../images/icon66.svg';
import icon7 from '../../images/icon77.svg';
import icon8 from '../../images/icon88.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <Container id="features">
      <div className="card">
        <div className="card-body">
          <Row>
            <Feature
              image={icon5}
              title={t('Feature1Title')}
              subTitle={t('Feature1SubTitle')}
              paragraph={t('Feature1Para')}
              imageAlt={t('Feature1ImageAlt')}
            />
            <Feature
              image={icon4}
              title={t('Feature2Title')}
              subTitle={t('Feature2SubTitle')}
              paragraph={t('Feature2Para')}
              imageAlt={t('Feature2ImageAlt')}
            />
            <Feature
              image={icon3}
              title={t('Feature3Title')}
              subTitle={t('Feature3SubTitle')}
              paragraph={t('Feature3Para')}
              imageAlt={t('Feature3ImageAlt')}
            />
            <Feature
              image={icon8}
              title={t('Feature4Title')}
              subTitle={t('Feature4SubTitle')}
              paragraph={t('Feature4Para')}
              imageAlt={t('Feature4ImageAlt')}
            />
            <Feature
              image={icon1}
              title={t('Feature5Title')}
              subTitle={t('Feature5SubTitle')}
              paragraph={t('Feature5Para')}
              imageAlt={t('Feature5ImageAlt')}
            />
            <Feature
              image={icon6}
              title={t('Feature6Title')}
              subTitle={t('Feature6SubTitle')}
              paragraph={t('Feature6Para')}
              imageAlt={t('Feature6ImageAlt')}
            />
            <Feature
              image={icon7}
              title={t('Feature7Title')}
              subTitle={t('Feature7SubTitle')}
              paragraph={t('Feature7Para')}
              imageAlt={t('Feature7ImageAlt')}
            />
            <Feature
              image={icon2}
              title={t('Feature8Title')}
              subTitle={t('Feature8SubTitle')}
              paragraph={t('Feature8Para')}
              imageAlt={t('Feature8ImageAlt')}
            />
          </Row>
        </div>
      </div>
    </Container>
  );
};
